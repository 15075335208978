import './App.css';
import Footer from './components/footer/Footer';
import HeroSection from './components/hero-section/HeroSection';
import Join from './components/join/Join';
import Plans from './components/plans/Plans';
import Programs from './components/programs/Programs';
import Reasons from './components/reasons/Reasons';
import Testimonials from './components/testimonials/Testimonials';
import Contact from './components/contact/Contact';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <>
    <div className="App">
    <Helmet>
           <title>Gym Town</title>
        <meta name="description" content="Welcome to Gym Town, your premier fitness destination in Hadapsar, Kalepadal, Pune. Join us for personalized training, group classes, and a supportive community." />
        <meta property="og:title" content="Gym Town - Fitness Center in Hadapsar, Pune" />
        <meta property="og:description" content="Join Gym Town for an unmatched fitness experience with top-notch facilities and expert trainers." />
        <meta property="og:image" content="URL_TO_YOUR_IMAGE" /> {/* Replace with your image URL */}
        <meta property="og:url" content="https://gymtown.fit" /> {/* Replace with your actual website URL */}
        <meta property="og:type" content="website" />
      </Helmet>
      <HeroSection/>
      <Programs/>
      <Reasons/>
      <Plans/>
      <Testimonials/>
      <Join />
      <Contact/>
      <Footer/>
    </div >
      </>
  );
}

export default App;
