import { useState } from 'react';
import './Contact.css';

const Contact = () => {
  // State for form fields and validation errors
  const [formValues, setFormValues] = useState({
    name: '',
    duration: '', // Replaced email with duration
    phone: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    duration: '', // Updated error state for duration
    phone: '',
    message: ''
  });

  // Event handler for input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });

    // Validate input fields
    validateField(name, value);
  };
 
  // Function to validate individual fields
  const validateField = (name, value) => {
    let error = '';

    switch (name) {
      case 'name':
        if (!value.match(/^[A-Za-z ]+$/)) {
          error = 'Only letters and spaces are allowed';
        }
        break;
      case 'duration':
        if (value === '') {
          error = 'Please select a duration';
        }
        break;
      case 'phone':
        if (!/^\d{10}$/.test(value)) {
          error = 'Phone number must be exactly 10 digits';
        }
        break;
      case 'message':
        if (value.trim() === '') {
          error = 'Message cannot be empty';
        }
        break;
      default:
        break;
    }

    setErrors({
      ...errors,
      [name]: error
    });
  };

  // Form submission handler
  const handleSubmit = (e) => {
    e.preventDefault();
    Object.keys(formValues).forEach((key) => validateField(key, formValues[key]));
    if (Object.values(errors).every((error) => error === '')) {
      // Create the message without asterisks
      const message = `New Contact Request\n` +
                      `Name: ${formValues.name}\n` +
                      `Email: ${formValues.email}\n` +
                      `Phone: ${formValues.phone}\n` +
                      `Message: ${formValues.message}`;
  
      const whatsappNumber = '918999854521'; // Replace with your WhatsApp number
      const url = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(message)}`;
  
      console.log(url); // Log the URL to check if it’s correct
      window.open(url, '_blank');
  
      // Clear the form fields after submission
      setFormValues({
        name: '',
        email: '',
        phone: '',
        message: ''
      });
    }
  };
  
  

  return (
    <div className="contact-form-container "  id='Contact'>
      <div className="form-section ">
        <div className="form-header">
          <h1 className='stroke-text'>Contact Us</h1>
          <p>Reach out to us for any inquiries or support. <br /> We're here to help with all your needs!</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="input-row">
            <input
              type="text"
              name="name"
              value={formValues.name}
              placeholder="Enter Your Name"
              required
              onChange={handleChange}
            />
            {errors.name && <p className="error">{errors.name}</p>}

            {/* Dropdown for duration */}
            <select
              name="duration"
              value={formValues.duration}
              onChange={handleChange}
              required
              className='dropdown' // Added class for styling
            >
              <option value="" disabled>Select Duration</option>
              <option value="1 month">1 Month</option>
              <option value="3 months">3 Months</option>
              <option value="6 months">6 Months</option>
              <option value="12 months">12 Months</option>
            </select>
            {errors.duration && <p className="error">{errors.duration}</p>}
          </div>
          
          <input
            type="tel"
            name="phone"
            className='emailsub'
            value={formValues.phone}
            placeholder="+91 | 9539****"
            required
            onChange={handleChange}
          />
          {errors.phone && <p className="error">{errors.phone}</p>}

          <div className="textarn">
            <textarea
              className='textarea'
              name="message"
              value={formValues.message}
              placeholder="How We Can Help?"
              rows="5"
              required
              onChange={handleChange}
            ></textarea>
            {errors.message && <p className="error">{errors.message}</p>}
          </div>
          
          <div className="submitbtn">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
      <div className="info-section">
        <div></div>
        <div className="info-item">
          <i className="icon fas fa-envelope"></i>
          <a href="mailto:rohitthokal127@gmail.com">rohitthokal127@gmail.com</a>
        </div>
        <div className="info-item">
          <i className="icon fas fa-map-marker-alt"></i>
          <a href="https://maps.app.goo.gl/rQe1WZhgo3ZDWYfE7" target="_blank" rel="noopener noreferrer"> Kale Borate Nagar Rd, Colony No 7  <br /> Kale Padal, Hadapsar, Pune, Maharashtra 411028</a>
        </div>
        <div className="info-item">
          <i className="icon fas fa-phone" style={{ transform: 'rotate(90deg)', display: 'inline-block' }} ></i>
          <a href="tel:+918999854521">+91 89998 54521</a>
        </div>
        <div className="mapdivider">
          <div className="info-item">
            <i className="icon fas fa-location-arrow"></i>
            <span style={{ color: 'white' }} >Get in Touch With GymTown</span>
          </div>
          <div className="map">
          <iframe
    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d472.99035977601744!2d73.94452290035086!3d18.487152218678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1729269610567!5m2!1sen!2sin"
    width="100%"
    height="250"
    style={{ border: 0 }}
    allowFullScreen
    loading="lazy"
    title="Google Maps"
    referrerPolicy="no-referrer-when-downgrade"
  ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
