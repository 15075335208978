import React, { useState } from 'react';
import Logo from '../../assets/GYMTOWN.png';
import Bars from '../../assets/bars.png';
import { Link } from 'react-scroll';
import './Header.css';

const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setMenuOpened] = useState(false);

  const handleScrollTo = (to) => {
    setMenuOpened(false);
    // Update URL hash
    window.history.pushState(null, null, `#${to}`);
  };

  return (
    <div className="header">
        <img src={Logo} alt="" className="logo"/>
        {menuOpened === false && mobile === true ? (
          <div style={{
            backgroundColor: 'var(--appColor)',
            padding: '0.5rem',
            borderRadius: '5px'
          }}
            onClick={() => setMenuOpened(true)}
          >
            <img 
              src={Bars} 
              alt="" 
              style={{
                width: '1.5rem', 
                height: '1.5rem'
              }}
            /> 
          </div>
          ) : (
          <ul className="header-menu">
            <li>
              <Link
                onClick={() => handleScrollTo('home')}
                activeClass="active"
                to='home'
                span={true}
                smooth={true}
              >
                Home
              </Link>
            </li>
            <li onClick={() => handleScrollTo('programs')}>
              <Link 
                onClick={() => handleScrollTo('programs')}
                to='programs'
                span={true}
                smooth={true}
              >
                Programs
              </Link>
            </li>
            <li>
              <Link 
                onClick={() => handleScrollTo('reasons')}
                to='reasons'
                span={true}
                smooth={true}
              >
                Why us
              </Link>
            </li>
            <li>
              <Link 
                onClick={() => handleScrollTo('plans')}
                to='plans'
                span={true}
                smooth={true}
              >
                Plans
              </Link>
            </li>
            <li>
              <Link 
                onClick={() => handleScrollTo('testimonials')}
                to='testimonials'
                span={true}
                smooth={true}
              >
                Testimonials
              </Link>
            </li>
            <li>
              <Link 
                onClick={() => handleScrollTo('Contact')}
                to='Contact'
                span={true}
                smooth={true}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        )}
    </div>
  );
}

export default Header;
