import React from 'react';
import './Footer.css';
import Instagram from '../../assets/instagram.png';
import Logo from '../../assets/GYMTOWN.png';
import justdial from '../../assets/justdial-seeklogo.svg';
import google from '../../assets/whitegmap.png';
import facebook from '../../assets/facebook.png';

const Footer = () => {
  const whatsappNumber = "919529424968"; // WhatsApp number

  const openWhatsApp = () => {
    window.open(`https://wa.me/${whatsappNumber}`, '_blank'); // Open WhatsApp chat
  };

  return (
    <div className="Footer-container">
      <hr />
      <div className='link'>
        <div className="footer">
          <div className="social-links">
            {/* Instagram Link */}
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <img src={Instagram} alt="Instagram" style={{ width: '2rem', height: '2rem' }} />
            </a>

            {/* Justdial Link */}
            <a href="https://www.justdial.com" target="_blank" rel="noopener noreferrer">
              <img style={{ width: '5rem' }} src={justdial} alt="Justdial" />
            </a>

            {/* Logo in the middle (no link) */}
            <div className="logo-f">
              <img src={Logo} alt="Logo" />
            </div>

            {/* Facebook Link */}
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <img className='fb' style={{ width: '6rem', height: '3rem' }} src={facebook} alt="Facebook" />
            </a>

            {/* Google Maps Link */}
            <a href="https://maps.google.com" target="_blank" rel="noopener noreferrer">
              <img src={google} alt="Google Maps" style={{ width: '2rem', height: '2rem' }} />
            </a>
          </div>
        </div>

        {/* WhatsApp link for 'Developed By Aniket Salunkhe' */}
        <div className="developer-info stroke-text" onClick={openWhatsApp}>
          Developed By Aniket Salunkhe
        </div>
      </div>

      <div className="blur blur-f-1"></div>
      <div className="blur blur-f-2"></div>
    </div>
  );
};

export default Footer;
