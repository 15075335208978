import image1 from "../assets/rohitThokal.jpeg";
import image2 from "../assets/swapnil1.jpeg";


export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
    name: 'Rohit Thokal',
    status : 'ENTREPRENEUR'
  },
  {
    image: image2,
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ipsam, ab itaque nam perferendis impedit sint ',
    name: 'Swapnil ',
    status: 'COACH'
  },

];
