import React, { useRef, useState } from 'react';
import { Client, Databases, ID } from 'appwrite';
import ConfettiExplosion from 'react-confetti-explosion'; // Import the library
import './Join.css';

// Initialize the Appwrite client
const client = new Client();
client
.setEndpoint('https://cloud.appwrite.io/v1')
.setProject('67135b35001ae2b52107')
// .setKey('standard_b9bba1f633edf001989d313738f9170518abd32bd3643f152be21ef4a11abf9bdc14725a70a7bb00121312e0c1c2b6f67fc6ecc0f6c1e083d9a9ece3deb6f21b3f31d3d0a5f2e74f9966fad6eddb78cfe3e0b1c4d70d424319ad38dfea7af997ff7a3b2ea45ddec8cf2ae7b0a4f9ff8370e691f91ec98aabc8e8fe2792380d95'); // Replace with your actual API key

const database = new Databases(client);

const Join = () => {
    const form = useRef();
    const [status, setStatus] = useState('');
    const [isExploding, setIsExploding] = useState(false); // State for explosion effect

    const handleJoin = async (e) => {
        e.preventDefault();
        setStatus('Processing...');

        const userEmail = form.current.user_email.value;

        try {
            setStatus('Saving email...');
            const response = await database.createDocument(
                '67135e0a00279b634bda',
                '67135e1600217c471d26',
                ID.unique(),
                { email: userEmail }
            );

            console.log('Document created successfully:', response);
            setStatus('Email saved successfully!');
            setIsExploding(true); // Trigger confetti explosion
            form.current.reset();

            // Stop explosion after a short duration
            setTimeout(() => {
                setStatus('You will get an email shortly!');
                setIsExploding(false); // Hide explosion effect
            }, 3000); // Adjust duration as needed
        } catch (error) {
            console.error('Error:', error);
            setStatus(`Error: ${error.message}`);
        }
    };

    return (
        <div className="Join" id="join-us">
            <div className="left-j">
                <hr />
                <div>
                    <span className="stroke-text">READY TO</span>
                    <span>LEVEL UP</span>
                </div>
                <div>
                    <span>YOUR BODY</span>
                    <span className="stroke-text">WITH US?</span>
                </div>
            </div>
            <div className="right-j">
                <form 
                    ref={form} 
                    className="email-container" 
                    onSubmit={handleJoin}
                >
                    <input 
                        type="email" 
                        name="user_email" 
                        placeholder="Enter your email address"
                        required
                    />
                                {isExploding && <ConfettiExplosion /> /* Show confetti explosion at the top */}

                    <button className="btn btn-j" type="submit">Join Now</button>
                </form>
                {status && <p>{status}</p>}
            </div>
        </div>
    );
};

export default Join;
